<template>
  <ul class="tableM" id="maintableM">
    <li>
      <em class="tableM tablename">아이디</em>
      <div class="w70w">{{data.memId}}</div>
    </li>
    <li>
      <em class="tableM tablename">닉네임</em>
      <div class="w70w">{{ data.memNick }}</div>
    </li>
    <li>
      <em class="tableM tablename">파트너등급</em>
      <div class="w70w">
        <span class="box" :class="data.myLevel">{{ data.myLevelName }}</span>
      </div>
    </li>
    <li>
      <em class="tableM tablename">소속</em>
      <div class="w70w">
        <div class="topwrap">
          <select class="h20 upperUser">
            <option v-for="item in data.topUserList" :key="item.recommenderId">
              <span>[{{item.partnerLevelName}}]</span>
              <span>{{item.recommenderId}}</span>
            </option>
          </select>
          <i class="icon">+</i>
        </div>
      </div>
    </li>
    <li>
      <em class="tableM tablename">롤링/루징<br>(카지노)</em>
      <div class="w70w">
        <div class="btn-input-wrap">
          <span>{{data.pointRate}}% / {{data.loseRate}}%</span>
          <a class="plus-btn"  @click="onOpenRate(data)"></a>
        </div>
      </div>
    </li>
    <li>
      <em class="tableM tablename">보유머니</em>
      <div class="w70w">
        <div class="btn-input-wrap">
          <span>{{ thousand(data.cashAmt) }}</span>
          <a class="inout-btn" v-if="data.myLevel === partnerLevelList[0].code" @click="onOpenPay(data)">지급회수</a>
        </div>
      </div>
    </li>
    <li>
      <em class="tableM tablename">보유포인트</em>
      <div class="w70w">
        <div class="btn-input-wrap">
          <span>{{ thousand(data.pointAmt) }}</span>
        </div>
      </div>
    </li>
    <li>
      <em class="tableM tablename">입/출금액</em>
      <div class="w70w">
        <div class="twoline-wrap">
          <span><em>입금</em>{{ thousand(data.totalCashInAmt) }}</span>
          <span><em>출금</em>{{ thousand(data.totalCashOutAmt) }}</span>
        </div>
      </div>
    </li>
    <li>
      <em class="tableM tablename">입출손익</em>
      <div class="w70w">{{thousand(data.totalCashInAmt - data.totalCashOutAmt)}}</div>
    </li>
    <li>
      <em class="tableM tablename">하부 생성</em>
      <div class="w70w">
        <div class="btn-input-wrap">
          <a class="inout-btn color-btn-y" v-if="data.myLevel === partnerLevelList[0].code" @click="$emit('addPartner', data)">하부생성</a>
        </div>
      </div>
    </li>
    <li>
      <em class="tableM tablename">가입 일시</em>
      <div class="w70w">{{dateFormatAll(data.joinDate)}}</div>
    </li>
    <li>
      <em class="tableM tablename"></em>
      <div class="w70w"></div>
    </li>
    <li>
      <a class="moreBtnM" @click="getMore" v-if="data.cntBotPartner > 0">
        <span :class="[ inSearchList.length > 0 ? 'minus' : 'plus' ]">
          <em>파트너 목록 열기</em>
          <em>파트너 목록 닫기</em>
        </span>
      </a><!-- ▴▾ -->
    </li>
  </ul>
  <ul class="tablePC">
    <li class="w13p plus-list" :class="{'nomore': !more && data.cntBotPartner > 0}" style="position: relative;">
      <span class="list-btn-wrap" :class="getLevelClass()">
        <a class="list-btn" @click="getMore" v-if="data.cntBotPartner > 0">
          <i class="fa" :class="[ inSearchList.length > 0 ? 'fa-minus' : 'fa-plus' ]"></i>
        </a>
        <span v-else class="list-btn none">
          <i class="fa else">•</i>
        </span>
      </span>
      <span class="list-name">{{ data.memId }}</span>
    </li>
    <li class="w7p">
      <span class="nick" :class="data.myLevel">{{ data.memNick }}</span> <!--등급에 따른 색 부여-->
    </li>
    <li class="w7p">
      <span class="box" :class="data.myLevel">{{ data.myLevelName }}</span>
    </li>
    <li class="w11p">
      <div class="topwrap">
        <select class="h20 upperUser">
          <option v-for="item in data.topUserList" :key="item.recommenderId">
            <span>[{{item.partnerLevelName}}]</span>
            <span>{{item.recommenderId}}</span>
          </option>
        </select>
        <i class="icon">+</i>
      </div>
    </li>
    <li class="w11p">
      <div class="btn-input-wrap">
        <span class="mw-73">{{data.pointRate}}% / {{data.loseRate}}%</span>
        <a class="plus-btn" @click="onOpenRate(data)"></a>
      </div>
    </li>
    <li class="w13p">
      <div class="btn-input-wrap mw-100">
        <span class="mw-73">{{ thousand(data.cashAmt) }}</span>
        <a class="inout-btn" v-if="data.myLevel === partnerLevelList[0].code" @click="onOpenPay(data)">지급회수</a>
      </div>
    </li>
    <li class="w13p">
      <div class="btn-input-wrap">
        <span class="mw-73">{{ thousand(data.pointAmt) }}</span>
<!--        <a class="inout-btn" @click="onOpenPoint()">지급회수</a>-->
      </div>
    </li>
    <li class="w13p">
      <div class="twoline-wrap">
        <span><em>입금</em>{{ thousand(data.totalCashInAmt) }}</span>
        <span><em>출금</em>{{ thousand(data.totalCashOutAmt) }}</span>
      </div>
    </li>
    <li class="w11p">{{thousand(data.totalCashInAmt - data.totalCashOutAmt)}}</li>
    <li class="w7p">
      <div class="btn-input-wrap">
        <a class="inout-btn color-btn-y" v-if="data.myLevel === partnerLevelList[0].code" @click="$emit('addPartner', data)">하부생성</a>
      </div>
    </li>
    <li class="w13p">{{dateFormatAll(data.joinDate)}}</li>
  </ul>

  <div v-if="more && inSearchList.length > 0" class="morelist">
      <component :is="component" v-if="inSearchList.length > 0" :key="data.memId"
                 :table="'more'"
                 :list="inSearchList"
                 :reqData="reqData"
                 :level=nextLevel
                 :partnerLevelList="partnerLevelList"
                 @addPartner="$emit('addPartner')"
                  />
  </div>

  <transition name="fade">
    <Paymodal v-if="PayMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePay"/>
  </transition>
  <transition name="fade">
    <PayPointmodal v-if="PayPMopen" :selectMem="selectMem" :selectIdx="selectIdx" @close="onClosePoint"/>
  </transition>
  <transition name="fade">
    <RateTable v-if="RateOpen" :selectMem="selectMem" @close="onCloseRate"/>
  </transition>
  <!-- <component :is="component" /> -->
</template>

<script>
// import PartnerListTable from '@/components/member/stributor/PartnerListTable.vue'
// import { defineAsyncComponent } from 'vue'
import Paymodal from '@/components/common/Pay.vue'
import PayPointmodal from '@/components/common/PayPoint.vue'
import RateTable from '@/components/common/RateTable.vue'
import { thousand } from '../../../libs/utils'
import { defineAsyncComponent } from 'vue'
import { retailMyPartnerList2 } from '@/api/retail'

export default {
  name: 'PartnerListTr',
  components: {
    // PartnerListTable
    Paymodal,
    PayPointmodal,
    RateTable
  },
  props: [
    'data',
    'reqData',
    'partnerLevelList',
    'level',
    'index'
  ],
  data () {
    return {
      PayMopen: false,
      PayPMopen: false,
      RateOpen: false,
      more: false,
      inSearchList: [],
      nextLevel: 0
    }
  },
  computed: {
    component () {
      return defineAsyncComponent(() => import('@/components/member/stributor/PartnerListTable.vue'))
    }
  },
  created () {
    console.log(this.level + 1)
    this.nextLevel = this.level + 1
  },
  methods: {
    async getMore () {
      this.emitter.emit('Loading', true)
      console.log(this.inSearchList)
      if (this.inSearchList.length === 0) {
        const reqData = { ...this.reqData }
        reqData.searchType = 'INNER'
        reqData.memId = this.data.memId
        console.log(reqData)
        const res = await retailMyPartnerList2(reqData)
        const result = res.data
        if (result.resultCode === '0') {
          console.log(result.data.list)
          this.inSearchList = result.data.list
        }
      } else {
        this.inSearchList = []
      }
      this.more = !this.more
      this.emitter.emit('Loading', false)
    },
    getLevelClass () {
      if (this.level === 1) {
        return 'first'
      }
      if (this.level === 2) {
        return 'second'
      }
      if (this.level === 3) {
        return 'third'
      }
      if (this.level === 4) {
        return 'fourth'
      }
    },
    thousand,
    onClosePay (newCash, idx) {
      if (newCash !== null) {
        console.log(this.index, newCash)
        this.$emit('updateCash', this.data, this.index, newCash)
      }
      this.PayMopen = false
      this.selectMem = null
    },
    onOpenPay (selectMem) {
      this.selectMem = {
        ...selectMem,
        cashAmt: thousand(selectMem.cashAmt)
      }
      this.PayMopen = true
    },
    onOpenRate (selectMem) {
      this.selectMem = {
        ...selectMem
      }
      this.RateOpen = true
    },
    onCloseRate () {
      this.RateOpen = false
      this.selectMem = null
    }
  }
}
</script>

<style src="@/styles/table.css"></style>
<style src="@/styles/striNew.css"></style>

<style scoped>
.mw-100 {min-width: 100px;}
.mw-73 {min-width: 73px;}
.w70w{min-height: 40px; padding-left: 5px !important; box-sizing: border-box;}
.nick.PTN_2{color: #363b72;}
.nick.PTN_3{color: #1d505c;}
.nick.PTN_4{color: #af2a2e;}
.nick.PTN_5{color: #d98f00;}
.tablePC{box-sizing: border-box; height: 60px; align-items: center;}
.tablePC li{box-sizing: border-box; overflow-x: scroll;}
.tablePC li::-webkit-scrollbar {display: none;}
.tablePC li.plus-list{height: 100%; display: flex; align-items: center; overflow: visible;}
.list-btn-wrap, .list-name {height: 60px;display: flex;align-items: center;}
.list-btn-wrap {padding-right: 13px;margin-left: 22px;}
.list-name {width: 100%;padding-left: 5px;}
.list-btn-wrap.second {margin-left: 36px;}
.list-btn-wrap.third {margin-left: 50px;}
.list-btn-wrap.fourth {margin-left: 64px;}
.plus {}
.else {}
.more {}
.list-btn {display: inline-block; width: 14px; height: 14px; position: relative;}
.list-btn::after, .list-btn::before {position: absolute; content: ''; background: #707070;}
.list-btn.none::after, .list-btn.none::before {display: none;}
.list-btn::after {width: 7px; height: 1px; top: 67px;left: 7px; opacity: 0;}
.morelist {}
.morelist ul:last-child .list-btn::after,
.morelist ul:last-child .list-btn::before,
.strTable .tablePC:last-child .list-btn::before {content: none;}

/*.list-btn::before {width: 1px; height: 46px; top: 46px; left: 6px;}
.else .list-btn::after {content: none;}*/

.list-btn::before {width: 1px; height: 54px; top: 14px; left: 7px; opacity: 0;}
/*.nomore .list-btn::after, .nomore .list-btn::before {content: none;}*/

.fa {width: 14px;height: 14px;border-radius: 2px;background-color: #707070;color: #fff;display: flex;align-items: center;justify-content: center;}
.fa-minus {background: url(~@/assets/img/icon/icon_minus.svg) center;}
.fa-plus {background: url(~@/assets/img/icon/icon_plus.svg) center;}

@media (max-width: 500px) {
  #maintableM {width: 100%;}
  .twoline-wrap {width: 100%;}
  .plus em:nth-child(2){display: none;}
  .minus em:nth-child(1){display: none;}
}
</style>
