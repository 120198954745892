<template>
  <div class="line">
    <div class="strTable" v-if="gameCount">
      <div class="tablePC" v-if="table === 'main'">
        <ul class="w100p tableHead">
          <li class="w13p">아이디</li>
          <li class="w7p">닉네임</li>
          <li class="w7p">파트너등급</li>
          <li class="w11p">소속</li>
          <li class="w11p">롤링/루징<br>(카지노)</li>
          <li class="w13p">보유머니</li>
          <li class="w13p">보유포인트</li>
          <li class="w13p">입/출금액</li>
          <li class="w11p">입출손익</li>
          <li class="w7p">하부 생성</li>
          <li class="w13p">가입 일시</li>
        </ul>
      </div>
      <template v-for="(item, index) in list" :key="item.memId">
        <partner-list-tr :data="item" :reqData="reqData"  :partnerLevelList="partnerLevelList" :level="level" @addPartner="onAddPartner" :index="index" @updateCash="onUpdateCash"/>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import PartnerListTr from '@/components/member/stributor/PartnerListTr.vue'

export default {
  name: 'PartnerListTable',
  components: {
    PartnerListTr
  },
  props: [
    'list',
    'reqData',
    'partnerLevelList',
    'level',
    'table'
  ],
  data () {
    return {
    }
  },
  watch: {
  },
  computed: {
    ...mapState(['gameCount', 'commonCode', 'gameGroupCodes'])
  },
  methods: {
    onAddPartner (value) {
      this.$emit('addPartner', value)
    },
    onUpdateCash (selectMem, idx, newCash) {
      this.$emit('updateCash', selectMem, idx, newCash)
    }
  }
}
</script>

<style src="@/styles/table.css"></style>
